import React from "react"

import styled from "styled-components"

import Text from "../Text"

const StyledWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
`

const StyledInput = styled.input`
  appearance: none;
  background-color: transparent;
  margin: 0;
  position: relative;
  cursor: pointer;
  margin-right: 10px;

  &::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid ${({ theme }) => theme.color.text.default};
    margin-bottom: -0.2rem;
  }

  &::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    top: 2px;
    left: 2px;
    position: absolute;
    background: transparent;
    transition: 0.1s ease-in;
  }

  &:checked::after {
    background: ${({ theme }) => theme.color.cyan_400};
  }
`

const Checkbox = ({ children, innerRef, name, error }) => (
  <StyledWrapper>
    <label htmlFor={name} style={{ userSelect: "none", display: "flex", alignItems: "flex-end" }}>
      <StyledInput ref={innerRef} id={name} name={name} type="checkbox" />
      {children}
    </label>
    <Text color="purple" fontSize="1rem">
      {error ? error.message || `Field is required` : "\u00a0"}
    </Text>
  </StyledWrapper>
)

Checkbox.defaultProps = {}

Checkbox.propTypes = {}

export default Checkbox
