import styled from "styled-components"

import Button from "components/common/Button"

export const StyledForm = styled.form`
  width: 100%;

  ${({ theme }) => theme.mq.xs} {
    div[class^="Grid__"] {
      display: block;
    }
  }
`

export const StyledWideInput = styled.div`
  grid-column: 1/3;

  ${({ theme }) => theme.mq.xs} {
    grid-column: 1/2;
  }
`

export const StyledFileNameContainer = styled.div`
  color: ${({ theme }) => theme.color.cyan_400};
  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    white-space: nowrap;
    margin-right: 1rem;
  }
`

export const StyledFooterWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.mq.xs} {
    width: 100%;
    justify-content: stretch;
  }
`

export const StyledButton = styled(Button)`
  display: inline-block;

  ${({ theme }) => theme.mq.xs} {
    flex-grow: 1;
  }

  &:disabled {
    background: ${({ theme }) => theme.color.gray_400};
  }
`

export const StyledButtonText = styled.span`
  font-size: 1.3rem;
  display: ${({ display }) => display} !important;
`

export const StyledButtonPreloader = styled.span`
  display: ${({ display }) => display} !important;
`

export const StyledImgPreloader = styled.img`
  width: 50px;
  top: -17px;
  position: relative;
`

export const StyledAttachButton = styled.label`
  display: grid;
  grid-template-columns: 35px auto;
  grid-gap: 1rem;
  cursor: pointer;

  ${({ theme }) => theme.mq.xs} {
    padding: 0 0 20px;
  }

  div {
    display: grid;
    grid-gap: 0.25rem;
    color: ${({ theme }) => theme.color.cyan_400};
    font-size: 1.2rem;
  }
`

export const StyledRemoveAttachment = styled.button`
  cursor: pointer;
  position: relative;
  background: ${({ theme }) => theme.color.cyan_400};
  border-radius: 100%;
  border: none;
  color: ${({ theme }) => theme.color.text.inverted};
  width: 40px;
  height: 40px;
  font-size: 10px;
  z-index: 1;
  box-shadow: 0px 0px 6px 1px rgba(70, 70, 70, 0.25);
`

export const StyledCheckboxWrapper = styled.div`
  padding-top: 2rem;
  grid-column: 1/3;
`

export const StyledMessage = styled.span`
  display: ${({ display }) => display};
  margin-right: 2rem;
  font-size: 1.4rem;
  opacity: ${({ opacity }) => opacity};
  color: #00c8e1;
  transition: opacity 0.2s linear;
  will-change: opacity;
`

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  color: #1a2028;
  font-size: 1.6rem;
  line-height: 2.4rem;

  ${({ theme }) => theme.mq.xs} {
    flex-direction: column-reverse;
  }
`

export const StyledLink = styled.a`
  color: #08b9db;
  transition: color 0.2s linear;
  will-change: color;

  &:hover {
    color: #4f79bd;
  }
`

const StyledPersonWidth = "35%"
const StyledPersonMR = "50px"

export const StyledFormWrapper = styled.div`
  width: calc(100% - ${StyledPersonWidth} - ${StyledPersonMR});
  ${({ theme }) => theme.mq.xs} {
    width: 100%;
  }
`

export const StyledPersonMobile = styled.div`
  width: 100%;
  text-align: center;
  margin: 20px 0;
  display: none;

  ${({ theme }) => theme.mq.xs} {
    display: flex;
    align-items: center;
  }

  .gatsby-image-wrapper {
    max-width: 150px;
  }
`

export const StyledPersonData = styled.div`
  margin-left: 15px;
  position: relative;
`

export const StyledPerson = styled.div`
  display: block;
  width: ${StyledPersonWidth};
  margin-right: ${StyledPersonMR};
  text-align: center;

  ${({ theme }) => theme.mq.xs} {
    display: none;
  }
`

export const StyledPersonName = styled.div`
  font-size: 1.6rem;
  line-height: 3rem;
  padding: 0.5rem 0 2rem;
`

export const StyledPersonPosition = styled.div`
  margin-bottom: 10px;
  font-size: 1.6rem;
`

export const StyledContainer = styled.div`
  display: flex;
`

export const StyledFormIntro = styled.p`
  width: 100%;
  ${({ theme }) => theme.mq.xs} {
    margin-bottom: 20px;
  }
`

export const StyledSeparator = styled.p`
  padding: 1rem 0;
`
