import React, { useEffect, useState } from "react"

import { StaticImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import { NotificationManager } from "react-notifications"

import preloader from "assets/images/preloader.gif"
import Checkbox from "components/common/Checkbox"
import Container from "components/common/Container"
import Grid from "components/common/Grid"
import Input from "components/common/Input"
import InputFile from "components/common/InputFile"
import Recaptcha from "components/common/Recaptcha"
import Section from "components/common/Section"
import Select from "components/common/Select"
import Text from "components/common/Text"
import textShortener from "helpers/textShortener"

import {
  StyledAttachButton,
  StyledButton,
  StyledButtonPreloader,
  StyledButtonText,
  StyledCheckboxWrapper,
  StyledContainer,
  StyledFileNameContainer,
  StyledFooterWrapper,
  StyledForm,
  StyledFormIntro,
  StyledFormWrapper,
  StyledHeader,
  StyledImgPreloader,
  StyledMessage,
  StyledPerson,
  StyledPersonData,
  StyledPersonMobile,
  StyledRemoveAttachment,
  StyledSeparator,
  StyledWideInput,
} from "./styles"

// const Styles

const acceptedFiles = [".pdf", ".jpg", ".jpeg", ".png"]

const ContactForm = () => {
  const { register, handleSubmit, errors, watch, setValue, reset } = useForm()
  const [phone, setPhone] = useState()
  const [fileName, setFileName] = useState(null)
  const [showFormMessage, setShowFormMessage] = useState(false)
  const [sending, setSending] = useState(false)
  const [token, setToken] = useState()
  const fileList = watch(["attachment"])

  useEffect(() => {
    const { attachment } = fileList
    if (attachment && attachment.length) {
      const { name } = attachment[0]
      const extension = `.${name.split(".").slice(-1).pop()}`

      if (acceptedFiles.includes(extension)) setFileName(textShortener(name, 45))
      else NotificationManager.error(`Extension ${extension} is not allow`, "Error", 4000)

      return
    }
    setFileName(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  const removeAttachment = () => {
    setValue("attachment", undefined, { shouldValidate: false })
    setFileName(null)
  }

  const onSubmit = data => {
    setSending(true)
    const formData = new FormData()
    formData.append("name", data.name)
    formData.append("email", data.email)
    formData.append("phone", phone || data.phone)
    formData.append("source", data.source)
    formData.append("details", data.details)
    formData.append("attachment", data.attachment[0])
    formData.append("privacy_policy", data.privacy_policy)
    formData.append("g-recaptcha-response", token)

    const requestOptions = {
      method: "POST",
      body: formData,
    }
    fetch(`${process.env.GATSBY_SERVICES_URL}/form/contact`, requestOptions)
      .then(res => res.json())
      .then(resJson => {
        if (resJson.errorMessage) {
          NotificationManager.error(resJson.errorMessage, "Error", 4000)
          setSending(false)
          return
        }
        if (resJson.err) {
          NotificationManager.error("Something went wrong.", "Error", 4000)
          setSending(false)
        }
        reset(formData)
        setPhone()
        document.getElementById("details").style.removeProperty("height")
        setShowFormMessage(true)
        setSending(false)
      })
      .catch(() => {
        NotificationManager.error("Something went wrong.", "Error", 4000)
        setSending(false)
      })
  }

  const selectOptions = [
    {
      label: "Building an MVP",
      value: "Building an MVP",
    },
    {
      label: "Custom software development",
      value: "Custom software development",
    },
    {
      label: "Data engineering",
      value: "Data engineering",
    },
    {
      label: "Team extension",
      value: "Team extension",
    },
  ]
  return (
    <Section>
      <Container size="m">
        <StyledContainer>
          <StyledPerson>
            <StaticImage alt="Contact" placeholder="Contact" src="../../../../assets/images/contact/contact.png" />
            <StyledSeparator />
            <StyledButton
              className="book-an-online-call-mobile-btn"
              color="yellow-gradient"
              display="none"
              href="https://jmrtech.pipedrive.com/scheduler/kOWarbH2/free-short-consultation-with-a-jmrs-expert"
              id="book-an-online-call"
              target="_blank"
            >
              <StyledButtonText>BOOK AN ONLINE CALL</StyledButtonText>
            </StyledButton>
          </StyledPerson>
          <StyledFormWrapper>
            <StyledHeader>
              <StyledPersonMobile>
                <div>
                  <StaticImage
                    alt="Contact"
                    placeholder="Contact"
                    src="../../../../assets/images/contact/contact.png"
                  />
                </div>
                <StyledPersonData>
                  <StyledButton
                    color="yellow-gradient"
                    display="none"
                    href="https://jmrtech.pipedrive.com/scheduler/kOWarbH2/free-short-consultation-with-a-jmrs-expert"
                    id="book-an-online-call"
                    target="_blank"
                  >
                    <StyledButtonText>BOOK AN ONLINE CALL</StyledButtonText>
                  </StyledButton>
                </StyledPersonData>
              </StyledPersonMobile>
              <StyledFormIntro>
                Nothing would give us more pleasure than meeting your needs. We&apos;ll be happy to offer you help from
                our team. Schedule an online meeting with our Tech Consultants or use the form. We&apos;ll invite you to
                a short online discovery call.
              </StyledFormIntro>
            </StyledHeader>
            <StyledForm enctype="multipart/form-data" method="post" onSubmit={handleSubmit(onSubmit)}>
              <Grid columns="1fr 1fr" columns_xs="auto" gap="1rem 6rem">
                <Input
                  color="black"
                  error={errors.name}
                  innerRef={register({ required: true })}
                  label="Name"
                  name="name"
                  type="text"
                />
                <Input
                  color="black"
                  error={errors.email}
                  innerRef={register({ required: true })}
                  label="Email"
                  name="email"
                  type="email"
                />
                <Input
                  color="black"
                  error={errors.phone}
                  getValue={val => setPhone(val)}
                  innerRef={register({ required: true })}
                  label="Phone number"
                  name="phone"
                  type="tel"
                  value={phone}
                />
                <Select
                  color="black"
                  error={errors.source}
                  innerRef={register({ required: "Field is required" })}
                  label="What are you looking for?"
                  name="source"
                  options={selectOptions}
                />
                <StyledWideInput>
                  <Input
                    color="black"
                    error={errors.details}
                    innerRef={register({ required: "Your message are required" })}
                    label="Your message"
                    name="details"
                    textarea
                  />
                </StyledWideInput>
                <StyledFileNameContainer>
                  <p>{textShortener(fileName, 25)}</p>
                  {fileName && <StyledRemoveAttachment onClick={removeAttachment}>X</StyledRemoveAttachment>}
                </StyledFileNameContainer>
                <StyledWideInput>
                  <InputFile
                    error={errors.attachment}
                    innerRef={register()}
                    inputProps={{ accept: acceptedFiles.join(", ") }}
                    name="attachment"
                    type="file"
                  >
                    {fileName ? (
                      <></>
                    ) : (
                      <StyledAttachButton>
                        <StaticImage
                          alt="Fast response"
                          placeholder="tracedSVG"
                          src="../../../../assets/images/office_clip.png"
                          style={{ width: "35px", height: "35px" }}
                        />
                        <div>
                          <Text bold color="cyan">
                            Add file (optional)
                          </Text>
                          <p>Supported formats: {acceptedFiles.map(format => format.replace(".", "")).join(", ")}</p>
                        </div>
                      </StyledAttachButton>
                    )}
                  </InputFile>
                </StyledWideInput>
                <StyledCheckboxWrapper>
                  <Checkbox error={errors.privacy_policy} innerRef={register({ required: true })} name="privacy_policy">
                    <Text element="span">
                      I accept{" "}
                      <Text element="a" href="/privacy-policy/" rel="nofollow" target="_blank" underline>
                        Privacy Policy
                      </Text>{" "}
                      and agree to be contacted by JMR
                    </Text>
                  </Checkbox>
                </StyledCheckboxWrapper>
              </Grid>
              <StyledFooterWrapper>
                <StyledMessage display="inline" id="success-message" opacity={showFormMessage ? 1 : 0}>
                  Your email has been sent. We reply as quick as we can.
                </StyledMessage>
                <StyledButton color="yellow-gradient" disabled={sending} id="contact-form-click" type="submit">
                  <StyledButtonText display={sending ? "none" : "block"}>Send</StyledButtonText>
                  <StyledButtonPreloader display={sending ? "block" : "none"}>
                    <StyledImgPreloader alt="Team extension graph" src={preloader} />
                  </StyledButtonPreloader>
                </StyledButton>
              </StyledFooterWrapper>
              <Recaptcha onToken={setToken} refresh={sending} />
            </StyledForm>
          </StyledFormWrapper>
        </StyledContainer>
      </Container>
    </Section>
  )
}

ContactForm.defaultProps = {}

ContactForm.propTypes = {}

export default ContactForm
