import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"

const StyledWrapper = styled.article`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;

  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: 1fr;
    grid-gap: 8rem;
  }
`
const StyledElement = styled.article`
  display: grid;
  grid-gap: 2rem;
  color: ${({ theme }) => theme.color.text.inverted};
  text-align: center;
  justify-items: center;
  max-width: 380px;

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.8rem;
  }

  p {
    font-size: 1.4rem;
    margin: 0 20px;
    min-height: 36px;
  }
`

const ContactInfo = ({ data }) => (
  <Section color="cyan">
    <Container size="l">
      <StyledWrapper>
        <StyledElement>
          <StaticImage
            alt="Fast response"
            placeholder="tracedSVG"
            src="../../../../assets/images/contact/fast-response.png"
            style={{ marginBottom: "2rem", width: "90px", height: "90px" }}
          />
          <h3>{data[0].title}</h3>
          <p>{data[0].content}</p>
        </StyledElement>
        <StyledElement>
          <StaticImage
            alt="Fast response"
            placeholder="tracedSVG"
            src="../../../../assets/images/contact/security.png"
            style={{ marginBottom: "2rem", width: "90px", height: "90px" }}
          />
          <h3>{data[1].title}</h3>
          <p>{data[1].content}</p>
        </StyledElement>
      </StyledWrapper>
    </Container>
  </Section>
)

ContactInfo.defaultProps = {}

ContactInfo.propTypes = {}

export default ContactInfo
