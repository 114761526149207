import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"
import ContactForm from "components/pages/contact/ContactForm"
import ContactInfo from "components/pages/contact/ContactInfo"

const query = graphql`
  query contactUsQuery {
    strapiContactUs {
      points {
        content
        id
        title
      }
      page_title
    }
  }
`

const Contact = ({ location }) => {
  const { strapiContactUs } = useStaticQuery(query)

  return (
    <TransitionWrapper location={location}>
      <Seo seoPageName="Contact" />
      <SubpageHeader subpageTitle={strapiContactUs.page_title} />
      <ContactForm />
      <ContactInfo data={strapiContactUs.points} />
    </TransitionWrapper>
  )
}

Contact.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Contact
