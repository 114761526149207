import React, { useState } from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

const colorResolver = (color, themeColors) => {
  switch (color) {
    case "black": {
      return {
        text: themeColors.text.default,
        outline: themeColors.black,
        background: themeColors.white_000,
        error: themeColors.purple,
      }
    }
    case "white":
    default: {
      return {
        outline: themeColors.white_000,
        text: themeColors.text.inverted,
        background: themeColors.cyan_400,
        error: themeColors.text.inverted,
      }
    }
  }
}

const StyledWrapper = styled.div`
  flex: 0 0 48%;
  position: relative;
  margin-bottom: 20px;
`

const StyledLabel = styled.label`
  font-size: 15px;
  position: absolute;
  left: 2px;
  top: 10px;
  color: ${({ color, theme }) => colorResolver(color, theme.color).text};
  transform-origin: left;
  transition: transform 0.25s ease-in;
  transform: translate3d(${({ expanded }) => (expanded ? "0, -20px, 0" : "0,0,0")})
    scale(${({ expanded }) => (expanded ? ".7" : "1")});
`

const StyledSelect = styled.select`
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom: ${({ color, theme }) => `1px solid ${colorResolver(color, theme.color).outline}`};
  width: 100%;
  margin: 0;
  color: ${({ color, theme }) => colorResolver(color, theme.color).text};
  padding: 8px 2px 8px 2px;
  transition: border 0.25s 0.1s ease-in;
  height: 3.5em;
  resize: none;
  overflow-y: hidden;

  &:focus {
    outline: none;

    ${StyledLabel} {
      bottom: 37px;
      left: 2px;
      -webkit-transform: scale(0.85);
      transform: scale(0.85);
    }
  }
`

const StyledOptions = styled.option`
  padding: 10px 20px;
`

const StyledError = styled.p`
  color: ${({ color, theme }) => colorResolver(color, theme.color).error};
`

const Select = props => {
  const { name, options, label, innerRef, error, color } = props
  const [expanded, setExpanded] = useState(false)

  const onChange = ({ target }) => {
    setExpanded(!!target.value)
  }
  const onFocus = () => setExpanded(true)
  const onBlur = ({ target }) => setExpanded(!!target.value)

  return (
    <StyledWrapper>
      <StyledLabel color={color} expanded={expanded} htmlFor={name}>
        {label}
      </StyledLabel>
      <StyledSelect
        ref={innerRef}
        color={color}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      >
        <StyledOptions />
        {options.map(opt => (
          <StyledOptions key={opt.value} value={opt.value}>
            {opt.label}
          </StyledOptions>
        ))}
      </StyledSelect>
      <StyledError color={color}>{error ? error.message || `${label} is required` : "\u00a0"}</StyledError>
    </StyledWrapper>
  )
}

Select.defaultProps = {
  color: "white",
  error: null,
}

Select.propTypes = {
  color: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  innerRef: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.string]).isRequired,
}

export default Select
